export default {
	computed: {
		isFetching() {
			return this.$fetchState?.pending && !this.$fetchState?.error;
		},
	},
	methods: {
		stripTags(originalString) {
			if (typeof originalString !== "string") {
				return originalString;
			}
			originalString = originalString.replace(/&nbsp;/gi, " ");
			return originalString.replace(/(<([^>]+)>)/gi, "");
		},

		// getLegalDocByKey(key) {
		// 	let result = null;
		// 	for (const form of this.$store.state.legalForms) {
		// 		result = form.forms.find((doc) => {
		// 			return doc.key === key;
		// 		});

		// 		if (result) {
		// 			break;
		// 		}
		// 	}

		// 	return result;
		// },

		crmDomain(path) {
			function removeEndingSlash(string) {
				if (string.endsWith("/")) {
					return string.slice(0, -1);
				}
				return string;
			}
			const parts = ["//" + this.$store.state.currentEntity?.name, this.$i18n.locale];
			if (path) {
				parts.push(path);
			}
			// check if there is __utm cookie and append it to the url
			const utmCookie = this.$store.state.utm;

			const concatedParts = parts.map(removeEndingSlash).join("/");
			return utmCookie ? concatedParts + "?" + utmCookie : concatedParts;
		},

		// loginCrmDomain(path) {
		// 	function removeEndingSlash(string) {
		// 		if (string.endsWith("/")) {
		// 			return string.slice(0, -1);
		// 		}
		// 		return string;
		// 	}
		// 	const parts = ["//" + this.$store.state.currentEntity?.crm_name, this.$i18n.locale];
		// 	if (path) {
		// 		parts.push(path);
		// 	}
		// 	return parts.map(removeEndingSlash).join("/");
		// },
	},
};
