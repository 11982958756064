export const state = () => ({
	languages: [],
	currentEntity: { configs: {}, translatable_configs: {}, entity: {}, regulation_entity: {}, portal_url: null, site_url: null },
	entity: null,
	legalForms: [],
	products: [],
	collectionErrors: {},
	hidden: [],
	mainMenu: [
		{
			title: "menu.about-us",
			children: [
				{
					title: "menu.who-are-we",
					to: {
						name: "about",
					},
					value: "about",
				},
				{
					title: "menu.business-partner",
					to: {
						name: "business-partner",
					},
					value: "business-partner",
				},
				{
					title: "menu.licenses-and-regulation",
					to: {
						name: "regulation",
					},
					value: "regulation",
				},
			],
		},
		{
			title: "menu.markets",
			value: "markets",
			children: [],
		},
		{
			title: "menu.tools",
			children: [
				{
					title: "menu.trading-platform",
					to: {
						name: "trading-platforms",
					},
				},
				{
					title: "menu.payment-methods",
					to: {
						name: "deposits-and-withdrawals",
					},
				},
				{
					title: "menu.calculator",
					to: {
						name: "calculator",
					},
					value: "calculator",
				},
				{
					title: "menu.copy-trading",
					to: {
						name: "copy-trading",
					},
					value: "copy-trading",
				},
				{
					title: "menu.mam",
					to: {
						name: "mam-pro-system",
					},
					value: "mam",
				},
			],
		},
		{
			title: "menu.account-types",
			value: "au-account-types",
			children: [
				{
					title: "menu.professional",
					to: {
						name: "account-types-classification",
						params: { classification: "professional" },
					},
				},
				{
					title: "menu.retail",
					to: {
						name: "account-types-classification",
						params: { classification: "retail" },
					},
				},
			],
		},
		{
			title: "menu.partnerships",
			value: "partnerships",
			children: [
				{
					title: "menu.overview",
					to: {
						name: "overview",
					},
				},
				{
					title: "menu.introducing-broker",
					to: {
						name: "introducing-broker",
						params: { type: "introducing-broker" },
					},
					value: "introducing-broker",
				},
				{
					title: "menu.cpa-affiliate",
					to: {
						name: "cpa-affiliate-partnership",
						params: { type: "cpa-affiliate" },
					},
					value: "cpa-affiliate",
				},
				{
					title: "menu.referral",
					to: {
						name: "referral-partnership",
					},
					value: "referral-partnership",
				},
			],
		},
		{
			title: "menu.account-types",
			to: {
				name: "account-types",
			},
			value: "default-account-types",
		},
		{
			title: "menu.news",
			children: [
				{
					title: "menu.news-room",
					to: {
						name: "newsroom",
					},
					value: "newsroom",
				},
				{
					title: "menu.global-events",
					children: [
						{
							title: "menu.economic-calendar",
							to: {
								name: "economic-calendar",
							},
						},
						{
							title: "menu.announcements-and-holidays",
							to: {
								name: "announcements-and-holidays",
							},
						},
						{
							title: "menu.corporate-events",
							to: {
								name: "corporate-events",
							},
						},
					],
				},
				{
					title: "menu.blogs-and-trends",
					to: {
						name: "blogs-and-trends",
					},
					value: "blogs-and-trends",
				},
			],
		},
		{
			title: "menu.help-and-education",
			children: [
				{
					title: "menu.faqs",
					to: {
						name: "faq",
					},
				},
				{
					title: "menu.glossary",
					to: {
						name: "glossary",
					},
				},
				{
					title: "menu.educational",
					to: {
						name: "educational",
					},
					children: [
						{
							title: "menu.articles",
							to: {
								name: "articles",
							},
						},
						{
							title: "menu.videos",
							to: {
								name: "videos",
							},
						},
						{
							title: "menu.seminars-and-webinars",
							to: {
								name: "webinars",
							},
							value: "webinars",
						},
					],
				},
				// {
				// 	title: "Glossary",
				// 	to: {
				// 		name: "glossary",
				// 	},
				// },
			],
		},
	],
	referer: null,
	utm: null
});

export const getters = {
	mainMenu: (state) => {
		// recursive function to filter out any item.value that is included in state.hidden

		return state.mainMenu;
	},
	defaultLanguage: (state) => {
		return state.languages?.find((language) => language?.default);
	},
};

export const mutations = {
	setEntity(state, entity) {
		state.entity = entity;
	},
	setCollectionItem(state, { key, item }) {
		state[key] = item;
	},
	setCollectionError(state, { key, error }) {
		state.collectionErrors[key] = error;
	},
	setMenuChildren(state, { key, children }) {
		const menu = state.mainMenu;
		const menuKey = menu.find((item) => item.value === key);
		if (menuKey) {
			menuKey.children = children;
		}
	},
	hideMenuItem(state, key) {
		state.hidden.push(key);
	},
	hideUnsupported(state) {
		function filterMenu(menu) {
			return menu.filter((item) => {
				if (item.children) {
					item.children = filterMenu(item.children);
				}
				return !state.hidden.includes(item.value);
			});
		}

		state.mainMenu = filterMenu(state.mainMenu);
	},
	referer(state, referer) {
		state.referer = referer;
	},
	setUtm(state, utm) {
		state.utm = utm;
	}
};

export const actions = {
	getCollection({ commit, state }, params) {
		function encodeQueryData(data) {
			const ret = [];
			for (const d in data) ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
			return ret.join("&");
		}
		for (const key in params) {
			if (typeof params[key] === "object") {
				params[key] = this.$to.base64(`${params[key].url}?${encodeQueryData(params[key].params)}`);
			} else if (typeof params[key] === "string") {
				params[key] = this.$to.base64(params[key]);
			}
		}
		return this.$apiOrRedis("/v2/collection", { params })

		// .catch((error) => {
		// 	// console.dir(error);
		// 	console.error("GET Collection error:", error);
		// 	this.$error(error);
		// 	this.$sentry.captureException(error, { extra: { params } });
		// 	return error;
		// });
	},
	prepareMenu({ state }) {
		let productsMenu = [];
		const products = state.products;
		if (productsMenu && products) {
			productsMenu = products.map((product) => {
				const child = {
					title: product.name,
					to: {
						name: "market",
						params: {
							category: product.category,
							slug: product.slug,
						},
					},
				};
				if (product.children && product.children.length) {
					child.children = product.children.map((childProduct) => {
						return {
							title: childProduct.name,
							to: {
								name: "market",
								params: {
									category: childProduct.category,
									slug: childProduct.slug,
								},
							},
						};
					});
					child.to = {
						name: "market",
						params: {
							category: "browse",
							slug: product.slug,
						},
					};
				}

				return child;
			});
			// productsMenu.push({
			// 	to: { name: "swaps" },
			// 	title: "menu.swaps",
			// });
			this.commit("setMenuChildren", { key: "markets", children: productsMenu });
		}
	},
	async loadLanguageData({ commit, dispatch, state }) {
		try {
			const resp = await this.$apiOrRedis("/v2/website/supported-languages");

			commit("setCollectionItem", { key: "languages", item: resp });

			return Promise.resolve(resp);
		} catch (error) {
			this.$sentry?.captureException(error);
			console.error("loadLanguageData error:", error);
			return Promise.reject(error);
		}
	},

	loadStartupData({ commit, dispatch, state }) {
		const promises = [];
		promises.push(
			dispatch("getCollection", {
				languages: state.languages.length ? undefined : "/v2/website/supported-languages",
				currentEntity: "/v2/website/affiliate-websites/current",
				entities: "/v2/website/regulation-entities",
				products: "/v2/website/product-categories",
				// legalForms: "/v1/website/legal-forms-categories",
				// language: "/v1/lookups/static-translations/" + i18n.locale + "?fallback=en",
			}).then((resp) => {
				// delete resp.language;
				for (const key in resp) {
					if (typeof resp[key] === "object" && resp[key].error) {
						this.$sentry?.captureException(resp[key].error);
						commit("setCollectionError", { key, error: resp[key].error });
					} else {
						commit("setCollectionItem", { key, item: resp[key] });
					}
				}

				if (getters.isAnyCollectionError) {
					console.error("Store Collection:", getters.collectionErrors);
				}

				dispatch("prepareMenu");

				if (!state.currentEntity.configs.has_webinars) {
					commit("hideMenuItem", "webinars");
				}
				// if (!state.currentEntity.configs.business_partner) {
				// 	commit("hideMenuItem", "business-partner");
				// }
				if (!state.currentEntity.configs.has_newsroom) {
					commit("hideMenuItem", "newsroom");
				}
				if (!state.currentEntity.configs.has_calculator) {
					commit("hideMenuItem", "calculator");
				}
				if (!state.currentEntity.configs.has_blogs_and_trends) {
					commit("hideMenuItem", "blogs-and-trends");
				}
				if (!state.currentEntity.configs.has_copy_trading) {
					commit("hideMenuItem", "copy-trading");
				}
				if (!state.currentEntity.configs.has_mam_pro_system) {
					commit("hideMenuItem", "mam");
				}
				if (!state.currentEntity.configs.has_partnerships) {
					commit("hideMenuItem", "partnerships");
				}

				if (!state.currentEntity.configs.can_open_account_agent) {
					commit("hideMenuItem", "introducing-broker");
				}
				if (!state.currentEntity.configs.can_open_account_cpa) {
					commit("hideMenuItem", "cpa-affiliate");
				}
				if (!state.currentEntity.configs.can_open_account_referral) {
					commit("hideMenuItem", "referral-partnership");
				}

				if (state.currentEntity.iso === "au") {
					commit("hideMenuItem", "default-account-types");
				} else {
					commit("hideMenuItem", "au-account-types");
				}

				commit("hideUnsupported");


				return resp;
			})
		);

		// promises.push(
		// 	this.$axios
		// 		.$get(`${process.env.NUXT_ENV_TRANSLATIONS_API_URL}/v1/lookups/static-translations/${i18n.locale}`, {
		// 			params: {
		// 				fallback: process.env.NUXT_ENV_TRANSLATIONS_FALLBACK_LOCALE || undefined,
		// 			},
		// 		})
		// 		.then((resp) => {
		// 			i18n.setLocaleMessage(i18n.locale, resp);
		// 		})
		// );

		return Promise.all(promises);
	},

	async nuxtServerInit({ commit, dispatch, getters, state }, { i18n, $config, req, redirect, $cookies, $sentry, $logError }) {

		// if accessed from subdomain other than www, redirect to base domain like s1.ingotbrokers.com
		const hasSubdomain = req.headers.host.split(".").length > 2;
		if (hasSubdomain && req.headers.host.split(".")[0] === "s1" || req.headers.host.split(".")[0] === "s2") {
			// extract current url protocol
			const protocol = req.headers["x-forwarded-proto"] || (req.connection.encrypted ? "https" : "http");
			await redirect(301, `${protocol}://www.${req.headers.host.split(".").slice(1).join(".")}${req.url}`);
		}

		const isBad = req.url.startsWith("//");

		if (isBad) {
			console.log("🚀 isBad URL:", isBad)
			await redirect(301, "/");
			return;
		}

		if (process.env.NUXT_ENV_ALLOW_COOKIE_ENTITY) {
			commit("setEntity", $cookies.get("entity") || $config.defaultEntity);
		} else if ($config.isDev) {
			commit("setEntity", $config.defaultEntity);
		} else {
			commit("setEntity", req.headers.host);
		}

		// return if url is _health-check
		if (req.url === "/_health-check") {
			return;
		}

		console.log("Entity Set To:", state.entity);

		const before = Date.now();

		const isRoot = req.url === "/";

		// extract query params as array of keys from req.url
		const queryParams = req.url.split("?")[1]?.split("&")?.map((param) => param.split("=")[0]);

		const hasUtm = queryParams?.some((param) => param.startsWith("utm_"));


		if ($cookies.get("__utm", { path: '/', domain: '.' + req.headers.host?.replace('www.', '') })) {
			commit("setUtm", $cookies.get("__utm"));
		}

		if (hasUtm && req.headers.referer) {
			commit("referer", req.headers.referer);
		}

		// if the user on the root page, the do the following checks:
		//   1- if user has i18n_redirected cookie, then set the locale to the cookie value
		//   2- if not, then set locale based on the getter defaultLanguage

		// if the user is not on the root page, set the cookie to the current locale

		try {
			if (isRoot) {
				const locale = $cookies.get("i18n_redirected");
				if (locale) {
					if (i18n.locale !== locale) {
						const after = Date.now();
						// eslint-disable-next-line
						console.log("[93m%s\x1B[0m", "Redirect based on cookie time is: " + (after - before) + "ms");

						return await i18n.setLocale(locale); // this will not redirect immediately, it will when nuxtServerInit is finished
					} else {
						i18n.setLocaleCookie(locale);
						await dispatch("loadStartupData");
					}
				} else {
					await dispatch("loadLanguageData");

					const after = Date.now();
					// eslint-disable-next-line
					console.log("[33m%s\x1b[0m", "Language API Load time is: " + (after - before) + "ms");
					if (!getters.defaultLanguage?.abbreviation) {
						console.error("Default language not found");
					}
					const defaultLocale = getters.defaultLanguage?.abbreviation || "en";
					await i18n.setLocaleCookie(defaultLocale);
					return await i18n.setLocale(defaultLocale);
				}
			} else {
				await dispatch("loadLanguageData");

				// if i18n.local is not within supported languages in this entity, redirect to the default locale
				if (!state.languages.find((language) => language.abbreviation === i18n.locale)) {
					const defaultLocale = getters.defaultLanguage?.abbreviation || "en";

					return await i18n.setLocale(defaultLocale);
				}
				await i18n.setLocaleCookie(i18n.locale);
				await dispatch("loadStartupData")

			}
		} catch (e) {
			console.log("nuxtServerInit error:", e)
			$logError(e)
		}

		const after = Date.now();
		// eslint-disable-next-line
		console.log("[35m%s\x1b[0m", "Shared API Load time is: " + (after - before) + "ms");
	},
};
