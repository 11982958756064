
export default {
	name: "DefaultLayout",
	data() {
		return {
			systemBarHeight: 38,
			languageMenuModel: false,
			menuDrawerModel: false,
			panelSnapInstance: null,
			activePanel: null,
		};
	},
	head() {
		const head = {
			script: [],
			link: [
				// { rel: "preload", href: this.localeFont, as: "font", type: "font/woff2" },
				{ rel: "stylesheet", href: this.localeFont, as: "style" },
			],
			title: this.$store.state.currentEntity?.entity?.name || "",
			bodyAttrs: {
				dir: this.$i18n.localeProperties.dir,
				lang: this.$i18n.locale,
				class: "page-" + this.getRouteBaseName(),
			},
		};

		return head;
	},
	computed: {
		localeFont() {
			return "/fonts/" + this.$i18n.locale + ".css";
		},

		showLangMenu() {
			if (this.$store.state.languages && this.$store.state.languages.length > 1) {
				return true;
			} else return false;
		},
	},
	beforeCreate() {
		this.$vuetify.rtl = this.$i18n.localeProperties.dir === "rtl";
		this.$vuetify.lang.current = this.$i18n.locale;
	},
	mounted() {
		// if (!this.$store.state.currentEntity.translatable_configs.site_top_disclosure) {
		// 	const warningAlert = document.getElementById("warning-top-alert");
		// 	const theHeader = document.getElementById("the-header");
		// 	window.addEventListener("scroll", function () {
		// 		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		// 		if (scrollTop > 0) {
		// 			warningAlert.classList.add("custom-top-alert");
		// 			theHeader.classList.add("custom-style-header");
		// 		} else {
		// 			warningAlert.classList.remove("custom-top-alert");
		// 			theHeader.classList.remove("custom-style-header");
		// 		}
		// 	});
		// }

		// this.panelSnapInstance = new this.$PanelSnap({
		// 	panelSelector: "section, footer",
		// });

		// Save UTM params in cookies (any params starts with utm_)

		if (this.$route.query) {
			const cookieOptions = {
				// expires in 90 days
				expires: new Date(new Date().getTime() + 90 * 24 * 60 * 60 * 1000),
				// for all sub domains
				domain: "." + window.location.hostname.replace('www.', ''),
				// root path
				path: '/',
			}
			const utmParts = {};
			Object.keys(this.$route.query).forEach((key) => {
				if (key.startsWith("utm_")) {
					utmParts[key] = this.$route.query[key];
					this.$cookies.set(key, this.$route.query[key], cookieOptions);
				}
			});

			if (Object.keys(utmParts).length > 0) {


				const referer = this.$route.query.referer || this.$store.state.referer;

				if (referer) {
					this.$cookies.set("__referer", referer, cookieOptions);
					utmParts.referer = encodeURIComponent(referer);
				}

				// convert utm params to string and save it in __utm cookie
				const utmString = Object.keys(utmParts)
					.map((key) => `${key}=${utmParts[key]}`)
					.join("&");

			
				this.$cookies.set("__utm", utmString, cookieOptions);
				this.$store.commit("setUtm", utmString);
				

			}
		}

		// end of utm

		window.addEventListener(
			"keydown",
			function (e) {
				if (e.key === "F8") {
					/* eslint-disable-next-line */
					debugger;
				}
			},
			false
		);
		if (process.env.NODE_ENV === "production") {
			setTimeout(this.loadLiveChat, 2 * 1000);
		}
		import(`dayjs/locale/${this.$i18n.locale}`).then(() => {
			this.$dayjs.locale(this.$i18n.locale);
		});
	},
	methods: {
		activatePanel(panel) {
			this.activePanel = panel;
		},
		changeEntity(entity) {
			const utmCookie = this.$store.state.utm;
			console.log("//" + entity.affiliate_website.site_name + (utmCookie ? "?" + utmCookie : ""))
			window.location = "//" + entity.affiliate_website.site_name + (utmCookie ? "?" + utmCookie : "");
		},
		loadLiveChat() {
			if (!this.$store.state.currentEntity.configs.livechat_license) return;

			window.__lc = window.__lc || {};
			window.__lc.license = this.$store.state.currentEntity.configs.livechat_license;
			window.__lc.chat_between_groups = false;
			(() => {
				const lc = document.createElement("script");
				lc.type = "text/javascript";
				lc.async = true;
				lc.src = "https://cdn.livechatinc.com/tracking.js";
				const s = document.getElementsByTagName("script")[0];
				s?.parentNode?.insertBefore(lc, s);
			})();
		},
	},
};
